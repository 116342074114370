import styled from 'styled-components';

import React, {memo, useRef, useState} from 'react';

import {EditIcon} from '@Common/Images';
import {Tag} from '@Common/Tag';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseClickOutside from '@hooks/UseClickOutide';

import {ISwitchContainerProps} from './types';

const ContainerStyled = styled.div``;

const EditingStyled = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;

  justify-content: space-between;
`;

const TextButtonsStyled = styled.div`
  display: flex;
  border: none;
  min-height: 40px;

  width: 100%;
  border: 1px solid transparent;
  align-items: center;

  justify-content: space-between;
`;

const TextStyled = styled.div<{$isError?: boolean}>`
  color: #1e1d21;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
  min-height: 40px;
  display: flex;

  word-break: break-word;
  overflow: auto;
  padding: 0 0 0 6px;
  display: flex;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
`;

const Button = styled.button`
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  & path {
    fill: ${({theme}) => theme.colors.grey};
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
`;

const SwitchContainer = ({
  children,
  value,
  onSave,
  isError,
  tags,
  isNotEditing,
  isAlwaysEditing,
}: ISwitchContainerProps) => {
  const [editing, setEditing] = useState(!!isAlwaysEditing);
  const ref = useRef(null);

  UseClickOutside(ref, () => {
    onSave && onSave();
    !isAlwaysEditing && setEditing(false);
  });

  if (isNotEditing)
    return (
      <ContainerStyled>
        <TextButtonsStyled>
          <TextStyled $isError={isError}>
            <>{value}</>
          </TextStyled>
        </TextButtonsStyled>
      </ContainerStyled>
    );

  return (
    <ContainerStyled>
      {editing ? (
        <EditingStyled ref={ref}>{children}</EditingStyled>
      ) : (
        <TextButtonsStyled>
          <TextStyled $isError={isError}>
            {tags ? (
              <TagsWrapper>
                {tags.length ? (
                  tags.map((tag, key) => <Tag title={tag.label} key={key} />)
                ) : (
                  <TextParagraph color={Colors.textDark}>Ничего не выбрано</TextParagraph>
                )}
              </TagsWrapper>
            ) : (
              <>{value}</>
            )}
          </TextStyled>
          {onSave && (
            <Button onClick={() => setEditing(true)}>
              <EditIcon />
            </Button>
          )}
        </TextButtonsStyled>
      )}
    </ContainerStyled>
  );
};

export default memo(SwitchContainer);
