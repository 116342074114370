import {axiosDocument, instance} from 'api';

import {IApiResponse} from 'types/services';
import {
  IActivityProfile,
  IFeedbackProfile,
  IProfileMemberIn,
  IProfilesState,
} from 'types/state/Profiles';
import {IPutUser, IUser} from 'types/User';
interface IProfilesFilters {
  searchTerm: string | null;
  lastLogin?: string | null;
  role?: string | null;
  page: number;
}

export interface IPostProfileFeedback {
  profile_id: number;
  score: number;
  comment: string;
}
export const ProfileService = {
  async getAll({searchTerm, lastLogin, role, page}: IProfilesFilters) {
    const response = await instance.get<IProfilesState>(
      `/profile/?search=${searchTerm || ''}&last_login=${lastLogin || ''}${
        role ? '&' + role + '=true' : ''
      }&limit=50&offset=${(page - 1) * 50}`,
    );
    return response.data;
  },
  async getDropdownProfile() {
    const response = await instance.get<IProfilesState>('/profile-dropdown/?limit=50000&offset=0');
    return response.data.results;
  },
  async getAllWithoutLimit({searchTerm, lastLogin, role}: Omit<IProfilesFilters, 'page'>) {
    const response = await instance.get<IProfilesState>(
      `/profile/?search=${searchTerm || ''}&last_login=${lastLogin || ''}${
        role ? '&' + role + '=true' : ''
      }&limit=50000&offset=0`,
    );
    return response.data.results;
  },

  async delete(id: number) {
    await instance.delete(`/profile/${id}/`);
  },

  async update({id, data, avatar}: {data: IPutUser; id: number; avatar?: File | null}) {
    if (avatar) void axiosDocument.patch(`/profile/${id}/`, {avatar});
    return instance.patch<IUser>(`/profile/${id}/`, data);
  },

  async createFeedback(data: IPostProfileFeedback) {
    await instance.post('/profile-feedback/', data);
  },

  async getMemberInById(id: number) {
    return (await instance.get<IProfileMemberIn>(`/profile-member-in/${id}/`)).data;
  },

  async getFeedbackById(id: number) {
    return (await instance.get<IApiResponse<IFeedbackProfile>>(`/profile-feedback/?profile=${id}`))
      .data;
  },

  async getActivityById(id: number) {
    const response = await instance.get<IApiResponse<IActivityProfile>>(
      `/profile-activity/?created_by=${id}`,
    );
    return response.data.results;
  },

  async getCustomers() {
    return (await instance.get<IProfilesState>('/profile/?is_customer=true')).data.results;
  },

  async getTrackers() {
    return (await instance.get<IProfilesState>('/profile/?is_tracker=true')).data.results;
  },

  async getSupervisors() {
    return (await instance.get<IProfilesState>('/profile/?is_supervisor=true')).data.results;
  },

  async getExperts() {
    return (await instance.get<IProfilesState>('/profile/?is_expert=true')).data.results;
  },

  async getHireds() {
    return (await instance.get<IProfilesState>('/profile/?is_hired=true')).data.results;
  },

  async getById(id: string | number) {
    return (await instance.get<IUser>(`/profile/${id}/`)).data;
  },

  async import(data: File) {
    return await axiosDocument.post('/profile/import-profiles/', {file: data});
  },
};
