import {styled} from 'styled-components';

import {useParams} from 'react-router-dom';

import {axiosDocument} from '@api/instance';
import React, {memo, useEffect, useRef, useState} from 'react';

import {PlusIcon, TrashIcon} from '@Common/Images';
import {StyledLink} from '@Common/Link';
import {StyledText} from '@Common/Text';

import {Colors} from '@constants/enum';
import {acceptFiles} from '@constants/regex';

import UseMutationDraftDocument from '@hooks/UseMutationDraftDocument';

import {IDraftsListItem} from 'types/state/Drafts';

import {IContentDocumentProps} from './types';

const Documents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-left: 6px;
`;

const ContentDocumentWrapper = styled.div<{$isDragging: boolean}>`
  display: flex;
  border: ${({theme, $isDragging}) =>
    $isDragging ? `1px solid ${theme.colors.accentBlue}` : '1px solid transparent'};
  flex-direction: column;
  gap: 5px;
  width: 100%;
  min-height: 40px;
  justify-content: center;
`;

const DeleteWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
`;

const SelectFileButton = styled.button`
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & path {
    fill: ${({theme}) => theme.colors.grey};
  }
`;

const StyledTextLinks = styled(StyledText)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledHelper = styled.div`
  display: flex;
  margin: 0;
  padding-left: 6px;
  word-break: break-word;

  color: ${({theme}) => theme.colors.black};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.small};
`;

const FileInput = styled.input.attrs({
  type: 'file',
  accept: acceptFiles,
})`
  display: none;
`;

const ContentDocument = ({documentsLinks, type, isExtra}: IContentDocumentProps) => {
  const pageId = Number(useParams().id);
  const mutation = UseMutationDraftDocument(pageId);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  useEffect(() => {
    const fetchFiles = async () => {
      if (!documentsLinks) return;

      try {
        const files = await Promise.all(
          documentsLinks
            .filter(link => !!link)
            .map(async link => {
              const response = await fetch(link as string);
              const blob = await response.blob();
              const fileName = decodeURIComponent(link?.split('/').pop() || '') || 'file';
              return new File([blob], fileName, {type: blob.type});
            }),
        );
        setSelectedFiles(files);
      } catch (error) {
        console.error('Ошибка при загрузке файлов:', error);
      }
    };

    void fetchFiles();
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const newFilesArray = Array.from(files);

      if (isExtra) {
        onSaveLinks([...selectedFiles, ...newFilesArray]);
        setSelectedFiles(prev => (prev ? [...prev, ...newFilesArray] : newFilesArray));
      } else {
        setSelectedFiles([newFilesArray[0]]);
        onSaveLinks([newFilesArray[0]]);
      }
    }
  };

  const onSaveLinks = (filesToSave: File[]) => {
    if (filesToSave) {
      const formData = new FormData();
      if (!filesToSave.length) {
        mutation.mutate({
          [type]: isExtra ? null : '',
        });

        return;
      }

      filesToSave.forEach(file => {
        formData.append('extra_files', file);
      });

      if (isExtra) {
        void axiosDocument.patch<IDraftsListItem>(`/draft/${pageId}/`, formData);
      } else {
        mutation.mutate({
          [type]: filesToSave[0] ? filesToSave[0] : '',
        });
      }
    }
  };

  const onDeleteItem = (fileToDelete: File) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileToDelete.name));
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      const newFilesArray = Array.from(files);

      if (isExtra) {
        onSaveLinks([...selectedFiles, ...newFilesArray]);
        setSelectedFiles(prev => (prev ? [...prev, ...newFilesArray] : newFilesArray));
      } else {
        setSelectedFiles([newFilesArray[0]]);
        onSaveLinks([newFilesArray[0]]);
      }
    }
    setIsDragging(false);
  };

  return (
    <ContentDocumentWrapper
      $isDragging={isDragging}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}>
      <Documents>
        <StyledTextLinks $color={Colors.accentBlue}>
          {selectedFiles && selectedFiles.length > 0 ? (
            selectedFiles.map(file => {
              if (!file) return null; // Пропускаем пустые ссылки

              return (
                <DeleteWrapper key={file.name + file.size.toString()}>
                  <StyledLink
                    href={URL.createObjectURL(file)}
                    target="_blank"
                    rel="noopener noreferrer">
                    {file.name}
                  </StyledLink>
                  <SelectFileButton onClick={() => onDeleteItem(file)}>
                    <TrashIcon />
                  </SelectFileButton>
                </DeleteWrapper>
              );
            })
          ) : (
            <p>-</p>
          )}
        </StyledTextLinks>

        <Buttons>
          <SelectFileButton onClick={openFileSelector}>
            <PlusIcon />
          </SelectFileButton>
        </Buttons>
        <FileInput
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple={type === 'extra_files'}
        />
      </Documents>

      {type === 'extra_files' && <StyledHelper>Можно добавить несколько файлов</StyledHelper>}
    </ContentDocumentWrapper>
  );
};

export default memo(ContentDocument);
