import {axiosDocument, instance} from 'api';

import {IPatchRequestDraft, IPatchRequestField} from 'types/patchRequest';
import {IDraftsListItem, IDraftsState} from 'types/state/Drafts';

interface IDraftFilters {
  searchTerm: string | null;
  projectArea?: string | null;
  isInternal?: string | null;
  isArchived?: string | null;
  supervisor?: string | null;
  accelerator?: string | null;
  scoringStatus?: string | null;
  tracker?: string | null;
  acceleratorRequest?: string | null;
  expertise?: string | null;
  filterByUpdate?: string | null;
  page: number;
}

export const DraftsService = {
  async getAll({
    searchTerm,
    projectArea,
    isInternal,
    isArchived,
    supervisor,
    accelerator,
    tracker,
    acceleratorRequest,
    page,
    scoringStatus,
    expertise,
    filterByUpdate,
  }: IDraftFilters) {
    const response = await instance.get<IDraftsState>(
      `/draft/?search=${searchTerm || ''}&project_area=${projectArea || ''}&archived=${
        isArchived || ''
      }&is_internal=${isInternal || ''}&tracker=${tracker || ''}&supervisor=${
        supervisor || ''
      }&accelerator=${accelerator || ''}&requested_accelerator=${
        acceleratorRequest || ''
      }&scoring_status=${scoringStatus || ''}&expertise=${expertise || ''}&ordering=${
        filterByUpdate || ''
      }&limit=50&offset=${(page - 1) * 50}`,
    );

    return response.data;
  },

  async getById(id: number) {
    return (await instance.get<IDraftsListItem>(`/draft/${id}/`)).data;
  },

  async updateAccelerator(
    project_id: number,
    accelerator_id: number | null,
    workflow_id: number | null,
  ) {
    return await instance.post<IDraftsListItem>('/project-accelerator/', {
      project_id,
      accelerator_id,
      workflow_id,
    });
  },

  async create(data: IPatchRequestDraft) {
    return instance.post<IDraftsListItem>('/draft-create/', data);
  },

  async update(id: number, data: IPatchRequestDraft) {
    return instance.patch<IDraftsListItem>(`/draft/${id}/`, data);
  },

  async delete(id: number) {
    return instance.delete<IDraftsListItem>(`/draft/${id}/`);
  },

  async updateDocument(id: number, data: IPatchRequestField) {
    return axiosDocument.patch<IDraftsListItem>(`/draft/${id}/`, data);
  },
};
