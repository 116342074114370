import {styled} from 'styled-components';

import {useParams} from 'react-router-dom';

import React, {memo, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import ContentStatus from '@components/DraftsItem/Informations/Content/Status';

import {StyledContentRow} from '@Common/DraftsItem/StyledContentRow';
import MultipleDropdown from '@Common/Dropdown/Multiple';
import {DropdownMultipleType} from '@Common/Dropdown/Multiple/types';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {CheckedIcon, CrossIcon} from '@Common/Images';
import {TextInput} from '@Common/Input';
import StatusChecker from '@Common/StatusChecker';
import {TableItem} from '@Common/Table/Item';
import InformationDraftRow from '@Common/Table/Row/InformationDraft';
import {TextHeaderMedium, TextParagraph} from '@Common/Text';
import {FlexRowContainer} from '@Common/Utils';

import {Colors} from '@constants/enum';
import {OnlyNumbers} from '@constants/regex';
import {labelsFills} from '@constants/status';

import UseCfo from '@hooks/UseCfo';
import UseCompanies from '@hooks/UseCompanies';
import UseMutationDraft from '@hooks/UseMutationDraft';
import UseProjectArea from '@hooks/UseProjectArea';
import UseTags from '@hooks/UseTags';

import {formatDate} from '@utils/formatDate';
import {getOptionFromId, getOptionFromString} from '@utils/GetOptionsFromString';

import ContentDocument from './ContentDocument';
import ContentInternal from './Internal';
import {IDraftsInformationsContentProps, InfoData} from './types';

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const StyledIsDraft = styled.div`
  display: flex;
  padding-left: 6px;
`;

const DateWrapper = styled(FlexRowContainer)``;

const templateColumns = 'minmax(125px, 1fr) minmax(200px, 2fr)';

const DraftsInformationsContent = ({data, user}: IDraftsInformationsContentProps) => {
  const pageId = Number(useParams().id);
  const {cfoLabels} = UseCfo();
  const {is_project} = user;
  const is_status = useMemo(() => !is_project || data.in_work, [is_project, data.in_work]);
  const {companyLabels, isErrorCompany, isLoadingCompany} = UseCompanies();
  const {tagsLabels, isErrorTags, isLoadingTags} = UseTags();

  const {projectAreaLabels, isErrorProjectArea, isLoadingProjectArea} = UseProjectArea();

  const initialData = useMemo(() => {
    const intersection = tagsLabels.filter(tag => data.tags.includes(tag.label));
    return {
      tags: intersection,
      is_internal: data.is_internal,
      inner_cfo_id: getOptionFromId(data.inner_cfo_id, cfoLabels),
      company_id: getOptionFromId(data.company_id, companyLabels),
      tech_dev_status: getOptionFromString('tech_dev_status', data.tech_dev_status || 'none'),
      market_status: getOptionFromString('market_status', data.market_status || 'none'),
      project_area_id: getOptionFromId(data.project_area_id, projectAreaLabels),
      estimated_profit: data.estimated_profit,
      presentation: data.presentation,
      extra_files: data.extra_files,
      description: data.description,
    };
  }, [tagsLabels, cfoLabels, companyLabels, projectAreaLabels, data]);

  const [infoData, setInfoData] = useState<InfoData>(initialData);

  useEffect(() => {
    setInfoData(initialData);
  }, [initialData]);

  const onUndo = (
    field: keyof InfoData,
    startData: DropdownMultipleType | DropdownSingleType | boolean | string | null,
  ) => {
    setInfoData(prev => ({
      ...prev,
      [field]: startData,
    }));
  };
  const mutation = UseMutationDraft(pageId);

  const onSave = (field: keyof InfoData) => {
    if (field === 'tags') {
      mutation.mutate({
        tags: infoData.tags?.map(tag => tag.label) || [],
      });
    } else if (
      [
        'inner_cfo_id',
        'company_id',
        'tech_dev_status',
        'market_status',
        'project_area_id',
        'accelerator_id',
      ].includes(field)
    ) {
      const value = infoData[field] as DropdownSingleType;
      mutation.mutate({
        [field]: value?.value || null,
      });
    } else {
      const value = infoData[field] as string;
      mutation.mutate({
        [field]: value,
      });
    }
  };

  const handleSetInfoData =
    (field: keyof InfoData) =>
    (value: DropdownSingleType | DropdownMultipleType | boolean | string) => {
      setInfoData(prev => ({...prev, [field]: value}));
    };

  if (!pageId) return null;

  return (
    <StyledTable>
      <TextParagraph color={Colors.accentBlue}>
        Обязательные поля помечены звёздочкой (*)
      </TextParagraph>
      <StatusChecker
        loadingsArray={[isLoadingCompany, isLoadingProjectArea, isLoadingTags]}
        errorsArray={[isErrorCompany, isErrorProjectArea, isErrorTags]}>
        <InformationDraftRow
          title="Теги"
          onUndo={() => onUndo('tags', initialData.tags)}
          onSave={() => onSave('tags')}
          tags={infoData.tags}>
          <MultipleDropdown
            isAddedNewValue
            labels={tagsLabels}
            value={infoData.tags}
            isFocus
            handleChange={handleSetInfoData('tags')}
          />
        </InformationDraftRow>

        <StyledContentRow $templateColumns={templateColumns}>
          <TextHeaderMedium>Не опубликован</TextHeaderMedium>
          <StyledIsDraft>{data.is_draft ? <CheckedIcon /> : <CrossIcon />}</StyledIsDraft>
        </StyledContentRow>

        {(data.in_work || !is_project) && (
          <ContentStatus
            is_draft={data.is_draft}
            accelerator_id={data.accelerator_id}
            workflow_id={data.workflow_id}
          />
        )}

        <InformationDraftRow
          title="Внутренний"
          isGray={!is_status}
          value={infoData.is_internal ? <CheckedIcon /> : <CrossIcon />}
          onUndo={() => onUndo('is_internal', initialData.is_internal)}
          onSave={() => onSave('is_internal')}>
          <ContentInternal
            setIsInternal={handleSetInfoData('is_internal')}
            is_internal={infoData.is_internal}
          />
        </InformationDraftRow>

        <InformationDraftRow
          isGray={is_status}
          title={data.is_internal ? 'ЦФО' : 'Компания'}
          value={data.is_internal ? infoData.inner_cfo_id?.label : infoData.company_id?.label}>
          {/* {data.is_internal ? (
            <SingleDropdown
              labels={cfoLabels}
              value={infoData.inner_cfo_id}
              isFocus
              handleChange={handleSetInfoData('inner_cfo_id')}
            />
          ) : (
            <SingleDropdown
              labels={companyLabels}
              value={infoData.company_id}
              isFocus
              handleChange={handleSetInfoData('company_id')}
            />
          )} */}
        </InformationDraftRow>

        <InformationDraftRow
          title="Статус разработки технологии"
          isGray={!is_status}
          value={infoData.tech_dev_status?.label}
          onUndo={() => onUndo('tech_dev_status', initialData.tech_dev_status)}
          onSave={() => onSave('tech_dev_status')}>
          <SingleDropdown
            isFocus
            labels={labelsFills['tech_dev_status']}
            value={infoData.tech_dev_status}
            handleChange={handleSetInfoData('tech_dev_status')}
          />
        </InformationDraftRow>

        <InformationDraftRow
          isGray={is_status}
          title="Статус проработки рынка"
          value={infoData.market_status?.label}
          onUndo={() => onUndo('market_status', initialData.market_status)}
          onSave={() => onSave('market_status')}>
          <SingleDropdown
            isFocus
            labels={labelsFills['market_status']}
            value={infoData.market_status}
            handleChange={handleSetInfoData('market_status')}
          />
        </InformationDraftRow>

        <InformationDraftRow
          title="Планируемая выручка в ближайшие 3 года, тыс. руб."
          isGray={!is_status}
          value={infoData.estimated_profit}
          onUndo={() => onUndo('estimated_profit', initialData.estimated_profit)}
          onSave={() => onSave('estimated_profit')}>
          <TextInput
            regExp={OnlyNumbers}
            width="100"
            value={infoData.estimated_profit || ''}
            onChange={e => handleSetInfoData('estimated_profit')(e.target.value)}
          />
        </InformationDraftRow>

        <StyledContentRow $templateColumns={templateColumns} $isGray={is_status}>
          <TextHeaderMedium>Презентация*</TextHeaderMedium>
          <ContentDocument type={'presentation'} documentsLinks={[data.presentation]} />
        </StyledContentRow>

        <InformationDraftRow
          isGray={!is_status}
          title="Описание*"
          value={infoData.description}
          onUndo={() => onUndo('description', initialData.description)}
          onSave={() => onSave('description')}>
          <TextInput
            width="100%"
            value={infoData.description || ''}
            onChange={e => handleSetInfoData('description')(e.target.value)}
          />
        </InformationDraftRow>

        <StyledContentRow $templateColumns={templateColumns} $isGray={is_status}>
          <TextHeaderMedium>Дополнительные материалы</TextHeaderMedium>
          <ContentDocument isExtra type={'extra_files'} documentsLinks={data.extra_files} />
        </StyledContentRow>

        <InformationDraftRow
          isGray={!is_status}
          title="Направление*"
          value={infoData.project_area_id?.label}
          onUndo={() => onUndo('project_area_id', initialData.project_area_id)}
          onSave={() => onSave('project_area_id')}>
          <SingleDropdown
            isFocus
            labels={projectAreaLabels}
            value={infoData.project_area_id}
            handleChange={handleSetInfoData('project_area_id')}
          />
        </InformationDraftRow>

        <StyledContentRow $templateColumns={templateColumns} $isGray={is_status}>
          <TextHeaderMedium>Создан</TextHeaderMedium>
          <DateWrapper>
            <TableItem path={`/profile/${data.founder.id}`}>
              <TextParagraph color={Colors.accentBlue}>
                {formatDate(data.created_at)}
                {' ( '}
                {data.founder.fio || data.founder.email1} {')'}
              </TextParagraph>
            </TableItem>
          </DateWrapper>
        </StyledContentRow>
      </StatusChecker>
    </StyledTable>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default memo(connect(mapStateToProps)(DraftsInformationsContent));
