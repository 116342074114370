import {createGlobalStyle} from 'styled-components';

import Rosatom from 'assets/fonts/RST75.ttf';

const GlobalStyles = createGlobalStyle`
  
  @font-face {
    font-family: 'Rosatom';
    src: url(${Rosatom}) format('ttf');
  }
  * {
    box-sizing: border-box;
    font-family: 'Rosatom', system-ui;    
  }

  body, html {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;

  }

  body {
    overflow: hidden;
  }
  
  h3, h2 {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
  
  li {
    list-style-type: none;
  }
  p {
    margin: 0;
  }
  
  main {
    display: flex;
    min-height: 100vh;
  }
`;

export default GlobalStyles;
