import React from 'react';

import {CheckedIcon, CrossIcon} from '@Common/Images';

import {EditableInternalWrapper, IconWrapper} from './styled';
import {ContentInternalProps} from './types';

const ContentInternal = ({is_internal, setIsInternal}: ContentInternalProps) => {
  const isInternal = is_internal ? <CheckedIcon /> : <CrossIcon />;

  const toggleInternal = () => {
    setIsInternal(!is_internal);
  };

  return (
    <EditableInternalWrapper>
      <IconWrapper $isEditing={false} onClick={toggleInternal}>
        {isInternal}
      </IconWrapper>
    </EditableInternalWrapper>
  );
};

export default ContentInternal;
