import React, {useState} from 'react';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {EditIcon, SaveDisk} from '@Common/Images';
import {TextInput} from '@Common/Input';
import {TextHeader} from '@Common/Text';
import TooltipCustom from '@Common/Tooltip';

import {ContentBoardMenuButtonList, ContentBoardMenuWrapper} from './styled';
import {BoardMenuProps} from './types';

const ContentBoardMenu = ({children, pageTitle, editing}: BoardMenuProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(pageTitle as string);

  const handleEditing = () => {
    if (isEditing && editing) {
      editing.onSave(value);
    }
    setIsEditing(prev => !prev);
  };
  return (
    <ContentBoardMenuWrapper>
      {isEditing ? (
        <TextInput value={value} onChange={e => setValue(e.target.value)} />
      ) : (
        <TextHeader>{pageTitle}</TextHeader>
      )}

      <ContentBoardMenuButtonList>
        {editing && (
          <TooltipCustom content={isEditing ? 'Сохранить' : editing.tooltip}>
            <ContentBoardMenuButton
              isPaddingSmall
              action={handleEditing}
              image={isEditing ? <SaveDisk /> : <EditIcon />}
            />
          </TooltipCustom>
        )}
        {children}
      </ContentBoardMenuButtonList>
    </ContentBoardMenuWrapper>
  );
};

export default ContentBoardMenu;
