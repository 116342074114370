import styled from 'styled-components';

import {useNavigate, useParams} from 'react-router-dom';

import React, {useEffect, useMemo, useState} from 'react';

import {selectUser} from '@store/user/selectors';

import {
  SupportMeasureDropdownFields,
  SupportMeasureEditData,
  SupportMeasureInputFields,
} from '@services/support_measure';

import {StyledTable} from '@components/DraftsItem/CheckList/Content/styles';
import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {TextInput} from '@Common/Input';
import InformationDraftRow from '@Common/Table/Row/InformationDraft';
import {TextHeader} from '@Common/Text';

import {Colors} from '@constants/enum';

import UseAppSelector from '@hooks/UseAppSelector';
import {UseNavigationList} from '@hooks/UseNavigationList';
import UseSupportMeasure from '@hooks/UseSupportMeasure';

import item from './item';

const DraftContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const inputFields: {
  title: string;
  field: keyof SupportMeasureInputFields;
}[] = [
  {
    title: 'Название',
    field: 'name',
  },
  {
    title: 'Описание программы',
    field: 'description',
  },
  {
    title: 'Параметры поддержки',
    field: 'support_measures',
  },
  {
    title: 'Ссылка на источник',
    field: 'url',
  },
];

const dropdownFields: {
  title: string;
  field: keyof SupportMeasureDropdownFields;
}[] = [
  {
    title: 'Источник меры поддержки',
    field: 'found_id',
  },
  {
    title: 'Категория',
    field: 'category_id',
  },
];

const SupportMeasureCreatePage = () => {
  const pageId = Number(useParams().id);
  const {id} = UseAppSelector(selectUser);
  const navigate = useNavigate();
  const {createSupportMeasure, founds, categories} = UseSupportMeasure(pageId);
  UseNavigationList([
    {path: '/aggregator', title: 'Меры поддержки'},
    {path: window.location.pathname, title: 'Создание'},
  ]);

  const initialData: SupportMeasureEditData = useMemo(() => {
    return {
      name: '',
      description: '',
      found_id: null,
      support_measures: '',
      url: '',
      category_id: null,
    };
  }, []);

  const [infoData, setInfoData] = useState(initialData);

  useEffect(() => {
    setInfoData(initialData);
  }, [initialData]);

  const onUndo = (field: keyof typeof initialData, startData: string | DropdownSingleType) => {
    setInfoData(prev => ({
      ...prev,
      [field]: startData,
    }));
  };

  const onSave = () => {
    createSupportMeasure.mutate({
      ...infoData,
      found_id: infoData.found_id?.value ? +infoData.found_id?.value : null,
      category_id: infoData.category_id?.value ? +infoData.category_id?.value : null,
      created_by: id,
    });
    navigate('/aggregator');
  };

  const handleSetInfoData =
    (field: keyof SupportMeasureEditData) => (value: DropdownSingleType | string) => {
      setInfoData(prev => ({...prev, [field]: value}));
    };

  return (
    <DraftContentWrapper>
      <ContentBoardMenu pageTitle={item?.name}>
        <ContentBoardMenuButton title={'Сохранить'} action={onSave} image={null} />
      </ContentBoardMenu>

      <StyledTable>
        {/* <TextParagraph color={Colors.accentBlue}>
            Обязательные поля помечены звёздочкой (*)
          </TextParagraph> */}
        <TextHeader color={Colors.grey}>Информация</TextHeader>
        {inputFields.map(({field, title}, index) => (
          <InformationDraftRow
            isAlwaysEditing
            key={field}
            isGray={index % 2 === 0}
            title={title}
            value={infoData[field]}
            onUndo={() => onUndo(field, initialData[field])}>
            <TextInput
              width="100%"
              value={infoData[field] || ''}
              onChange={e => handleSetInfoData(field)(e.target.value)}
            />
          </InformationDraftRow>
        ))}

        {dropdownFields.map(({field, title}, index) => (
          <InformationDraftRow
            isAlwaysEditing
            key={field}
            isGray={index % 2 === 0}
            title={title}
            value={infoData[field]?.label}
            onUndo={() => onUndo(field, initialData[field])}>
            <SingleDropdown
              isFocus
              labels={field === 'found_id' ? founds.labels : categories.labels}
              value={infoData[field]}
              handleChange={handleSetInfoData(field)}
            />
          </InformationDraftRow>
        ))}
      </StyledTable>
    </DraftContentWrapper>
  );
};

export default SupportMeasureCreatePage;
