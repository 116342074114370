import styled from 'styled-components';

import {useNavigate, useParams} from 'react-router-dom';

import React, {useEffect, useMemo, useState} from 'react';

import {
  SupportMeasureDropdownFields,
  SupportMeasureEditData,
  SupportMeasureInputFields,
} from '@services/support_measure';

import {StyledTable} from '@components/DraftsItem/CheckList/Content/styles';
import {ContentBoardMenu} from '@components/Menu';

import {ContentBoardMenuButton} from '@Common/Buttons';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {TrashIcon} from '@Common/Images';
import {TextInput} from '@Common/Input';
import StatusChecker from '@Common/StatusChecker';
import InformationDraftRow from '@Common/Table/Row/InformationDraft';
import {TextHeader} from '@Common/Text';

import CategoriesTable from '@Tables/Aggregator/Categories';
import FoundsTable from '@Tables/Aggregator/Founds';

import {Colors} from '@constants/enum';

import {UseNavigationList} from '@hooks/UseNavigationList';
import UseSupportMeasure from '@hooks/UseSupportMeasure';

import {getOptionFromId} from '@utils/GetOptionsFromString';

const DraftContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const inputFields: {
  title: string;
  field: keyof SupportMeasureInputFields;
}[] = [
  {
    title: 'Название',
    field: 'name',
  },
  {
    title: 'Описание программы',
    field: 'description',
  },
  {
    title: 'Параметры поддержки',
    field: 'support_measures',
  },
  {
    title: 'Ссылка на источник',
    field: 'url',
  },
];

const dropdownFields: {
  title: string;
  field: keyof SupportMeasureDropdownFields;
}[] = [
  {
    title: 'Источник меры поддержки',
    field: 'found_id',
  },
  {
    title: 'Категория',
    field: 'category_id',
  },
];

const SupportMeasureItemPage = () => {
  const pageId = Number(useParams().id);
  const navigate = useNavigate();
  const {
    updateSupportMeasure,
    founds,
    categories,
    supportMeasure: item,
    isLoadingSupportMeasure,
    isErrorSupportMeasure,
    deleteSupportMeasure,
  } = UseSupportMeasure(pageId);

  UseNavigationList([
    {path: '/aggregator', title: 'Меры поддержки'},
    {path: window.location.pathname, title: item?.name || 'Loading...'},
  ]);

  const initialData: SupportMeasureEditData = useMemo(() => {
    return {
      name: item?.name || '',
      description: item?.description || '',
      found_id: getOptionFromId(item?.found_id || null, founds.labels),
      support_measures: item?.support_measures || '',
      url: item?.url || '',
      category_id: getOptionFromId(item?.category_id || null, categories.labels),
    };
  }, [item, founds.labels, categories.labels]);

  const [infoData, setInfoData] = useState(initialData);

  useEffect(() => {
    setInfoData(initialData);
  }, [initialData]);

  const onUndo = (field: keyof typeof initialData, startData: string | DropdownSingleType) => {
    setInfoData(prev => ({
      ...prev,
      [field]: startData,
    }));
  };

  const onSave = (field: keyof SupportMeasureEditData) => {
    if (['found_id', 'category_id'].includes(field as string)) {
      const value = infoData[field] as DropdownSingleType;
      updateSupportMeasure.mutate({
        [field]: value?.value || null,
      });
    } else {
      const value = infoData[field] as string;
      updateSupportMeasure.mutate({
        [field]: value,
      });
    }
  };

  const onDelete = () => {
    if (pageId) {
      deleteSupportMeasure.mutate(pageId);
      navigate('/aggregator');
    }
  };

  const handleSetInfoData =
    (field: keyof SupportMeasureEditData) => (value: DropdownSingleType | string) => {
      setInfoData(prev => ({...prev, [field]: value}));
    };

  return (
    <DraftContentWrapper>
      <ContentBoardMenu pageTitle={item?.name}>
        <ContentBoardMenuButton title={'Удалить'} action={onDelete} image={<TrashIcon />} />
      </ContentBoardMenu>
      <StatusChecker
        loadingsArray={[isLoadingSupportMeasure]}
        errorsArray={[isErrorSupportMeasure]}>
        <StyledTable>
          {/* <TextParagraph color={Colors.accentBlue}>
            Обязательные поля помечены звёздочкой (*)
          </TextParagraph> */}
          <TextHeader color={Colors.grey}>Информация</TextHeader>
          {inputFields.map(({field, title}, index) => (
            <InformationDraftRow
              key={field}
              isGray={index % 2 === 0}
              title={title}
              value={infoData[field]}
              onUndo={() => onUndo(field, initialData[field])}
              onSave={() => onSave(field)}>
              <TextInput
                width="100%"
                value={infoData[field] || ''}
                onChange={e => handleSetInfoData(field)(e.target.value)}
              />
            </InformationDraftRow>
          ))}

          {dropdownFields.map(({field, title}, index) => (
            <InformationDraftRow
              key={field}
              isGray={index % 2 === 0}
              title={title}
              value={infoData[field]?.label}
              onUndo={() => onUndo(field, initialData[field])}
              onSave={() => onSave(field)}>
              <SingleDropdown
                isFocus
                labels={field === 'found_id' ? founds.labels : categories.labels}
                value={infoData[field]}
                handleChange={handleSetInfoData(field)}
              />
            </InformationDraftRow>
          ))}
        </StyledTable>

        <FoundsTable />
        <CategoriesTable />
      </StatusChecker>
    </DraftContentWrapper>
  );
};

export default SupportMeasureItemPage;
