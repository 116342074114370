import styled from 'styled-components';

import {useQuery} from '@tanstack/react-query';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {selectFilters} from '@store/filters/selectors';
import {RootState} from '@store/index';

import {DraftsService} from '@services/drafts';

import {PaginationButtons} from '@components/Menu';

import FiltersPanel from '@Common/FiltersPanel';
import {CheckedIcon, CrossIcon} from '@Common/Images';
import StatusChecker from '@Common/StatusChecker';
import Table from '@Common/Table';
import TableHeader from '@Common/Table/Header';
import {TableItem} from '@Common/Table/Item';
import TableNotFoundItem from '@Common/Table/NotFound';
import {TableRowStyled} from '@Common/Table/Row';
import {TextParagraph} from '@Common/Text';

import {Colors} from '@constants/enum';

import {Props} from './types';

const DraftStateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const templateColumns =
  '20px minmax(200px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr)';

const DraftsTable = ({filters}: Props) => {
  const {
    search,
    page,
    supervisor,
    projectArea,
    isInternal,
    tracker,
    isArchived,
    acceleratorRequest,
    scoringStatus,
    filterByUpdate,
    expertiseRequired,
  } = filters;
  const [searchTerm, setSearchTerm] = useState('');

  const {data, isLoading, isError} = useQuery({
    queryKey: [
      'drafts',
      projectArea,
      isInternal,
      searchTerm,
      supervisor,
      isArchived,
      tracker,
      page,
      acceleratorRequest,
      scoringStatus,
      filterByUpdate,
      expertiseRequired,
    ],
    queryFn: async () =>
      await DraftsService.getAll({
        searchTerm,
        projectArea,
        isInternal,
        supervisor,
        tracker,
        isArchived,
        acceleratorRequest,
        page,
        scoringStatus,
        filterByUpdate,
        expertise: expertiseRequired,
      }),
  });

  const count = useMemo(() => data?.count, [data?.count]);

  useEffect(() => {
    if (!search) setSearchTerm(search);
  }, [search]);

  return (
    <>
      <FiltersPanel count={count} isDrafts handleEnterSearch={() => setSearchTerm(search)} />
      <StatusChecker errorsArray={[isError]} loadingsArray={[isLoading]}>
        <Table>
          <TableHeader
            templateColumns={templateColumns}
            titles={[
              {title: '№'},
              {title: 'Название'},
              {title: 'Компания/ИП/ЦФО'},
              {title: 'Куратор'},
              {title: 'Инициатор'},
              {title: 'Направление'},
              {title: 'Внутренний', align: 'center'},
              {title: 'Статус скоринга'},
            ]}
          />

          {data?.results?.length ? (
            data?.results?.map(
              (
                {id, name, company, founder, project_area, is_internal, supervisor, scoring_status},
                index,
              ) => {
                return (
                  <TableItem key={id} path={`/draft/${id}`}>
                    <TableRowStyled $templateColumns={templateColumns}>
                      <TextParagraph color={Colors.textDark}>{index + 1}</TextParagraph>
                      <TextParagraph color={Colors.accentBlue}>{name}</TextParagraph>
                      <TextParagraph color={Colors.textDark}>{company?.name}</TextParagraph>
                      <TextParagraph color={Colors.textDark}>
                        {supervisor?.fio || supervisor?.email1}
                      </TextParagraph>
                      <TextParagraph color={Colors.textDark}>{founder.fio}</TextParagraph>
                      <TextParagraph color={Colors.textDark}>{project_area?.name}</TextParagraph>
                      <DraftStateWrapper>
                        {is_internal ? <CheckedIcon /> : <CrossIcon />}
                      </DraftStateWrapper>
                      <TextParagraph color={Colors.textDark}>{scoring_status}</TextParagraph>
                    </TableRowStyled>
                  </TableItem>
                );
              },
            )
          ) : (
            <TableNotFoundItem />
          )}
        </Table>
      </StatusChecker>
      <PaginationButtons margin={15} count={count} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: selectFilters(state),
});

export default connect(mapStateToProps)(DraftsTable);
