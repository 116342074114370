import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import UserPicture from 'assets/images/user_picture.png';
import React, {useCallback, useState} from 'react';

import {selectUser} from '@store/user/selectors';

import {AttachmentService} from '@services/attachment';
import {CommentService} from '@services/comment';

import {Button} from '@components/DraftsItem/Informations/Content/Internal/styled';

import {TrashIcon} from '@Common/Images';
import Loader from '@Common/Loader';
import AcceptModal from '@Common/Modal/Accept';
import {Picture} from '@Common/Picture';
import {TextHeaderMedium, TextParagraph, TextSmall} from '@Common/Text';

import {commentsChoices} from '@constants/comments';
import {Colors} from '@constants/enum';

import UseAppSelector from '@hooks/UseAppSelector';

import {formatDate} from '@utils/formatDate';

import {IUserSmall} from 'types/User';

const StyledComment = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border: ${({theme}) => `1px solid ${theme.colors.greyCircuit}`};
  border-radius: ${({theme}) => theme.borderRadius.full};
  padding: 5px;
`;

export const StyledLink = styled.a.attrs<{href: string}>(({href}) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: href,
}))`
  display: flex;
  margin: 0;

  word-break: break-word;

  color: ${({theme}) => theme.colors.accentBlue};
  font-weight: ${({theme}) => theme.fontWeight.normal};
  font-size: ${({theme}) => theme.fontSize.small};
`;

const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

interface ICommentListItem {
  created_by: IUserSmall;
  action: string;
  created_at: string;
  text: string;
  comment_id: number;
}

const Comment = ({created_by, action, created_at, text, comment_id}: ICommentListItem) => {
  const pageId = Number(useParams().id);
  const {is_superuser, is_supervisor} = UseAppSelector(selectUser);
  const [isDelete, setIsDelete] = useState(false);
  const queryClient = useQueryClient();
  const {
    data: attachmentData,
    isLoading: isAttachmentLoading,
    isError: isAttachmentError,
  } = useQuery({
    queryKey: ['attachment', comment_id],
    queryFn: () => AttachmentService.getByComment(comment_id),
  });
  const mutationComment = useMutation({
    mutationFn: (data: number) => CommentService.delete(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(['comments', pageId]);
    },
  });

  const onDelete = () => {
    mutationComment.mutate(comment_id);
  };
  const getDisplayNameByValue = useCallback((value: string) => {
    const foundChoice = commentsChoices.find(choice => choice.value === value);
    return foundChoice?.display_name || null;
  }, []);

  if (isAttachmentError) return null;

  if (isAttachmentLoading) return <Loader />;

  const {avatar, fio, email1} = created_by;

  const userSrc = avatar || UserPicture;
  const surname = fio || email1;

  const typeText = getDisplayNameByValue(action);

  return (
    <>
      <StyledComment>
        <div style={{display: 'flex', gap: '10px'}}>
          <Picture variant={'sm'} src={userSrc} alt={'Аватар'} />
          <StyledReview>
            <TextHeaderMedium>{surname}</TextHeaderMedium>
            <TextSmall>{formatDate(created_at)}</TextSmall>
            <TextParagraph color={Colors.grey}>{typeText}</TextParagraph>
            <div
              style={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}
              dangerouslySetInnerHTML={{__html: text}}
            />
            {attachmentData.map(attachment => {
              const documentName = decodeURIComponent(attachment.attachment.split('/').pop() || '');
              return (
                <StyledLink key={attachment.id} href={attachment.attachment}>
                  {documentName}
                </StyledLink>
              );
            })}
          </StyledReview>
        </div>

        {(is_superuser || is_supervisor) && (
          <Button style={{alignSelf: 'start'}} onClick={() => setIsDelete(true)}>
            <TrashIcon />
          </Button>
        )}
      </StyledComment>
      {isDelete && <AcceptModal handleYes={onDelete} setIsOpen={setIsDelete} />}
    </>
  );
};

export default Comment;
