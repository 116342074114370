import {styled} from 'styled-components';

import {useParams} from 'react-router-dom';

import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {RootState} from '@store/index';
import {selectUser} from '@store/user/selectors';

import {ContentBoardMenuButton} from '@Common/Buttons';
import {StyledContentRow} from '@Common/DraftsItem/StyledContentRow';
import SingleDropdown from '@Common/Dropdown/Single';
import {DropdownSingleType} from '@Common/Dropdown/Single/types';
import {PlusIcon} from '@Common/Images';
import RegistrationModal from '@Common/Modal/Registration';
import StatusChecker from '@Common/StatusChecker';
import InformationDraftRow from '@Common/Table/Row/InformationDraft';
import {Colors, StyledText, TextHeaderMedium} from '@Common/Text';

import UseMutationDraft from '@hooks/UseMutationDraft';
import UseProfiles from '@hooks/UseProfiles';

import {getOptionFromId} from '@utils/GetOptionsFromString';

import {IDraftsListItem} from 'types/state/Drafts';
import {IUser} from 'types/User';

import {IData} from './types';

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFounder = styled(StyledText)`
  padding-left: 6px;
`;

type DraftsParticipantsContentProps = {
  data: IDraftsListItem;
  user: IUser;
};

const DraftsParticipantsContent = ({data, user}: DraftsParticipantsContentProps) => {
  const {profilesLabels, isErrorProfiles, isLoadingProfiles, supervisorsLabels} = UseProfiles();
  const pageId = Number(useParams().id);
  const {is_project, is_customer, is_expert} = user;

  const [isRegistrationModalShown, setIsRegistrationModalShown] = useState(false);
  const initialData = useMemo(
    () => ({
      lead_id: getOptionFromId(data.lead_id, profilesLabels),
      tech_spec_id: getOptionFromId(data.tech_spec_id, profilesLabels),
      comm_spec_id: getOptionFromId(data.comm_spec_id, profilesLabels),
      tracker_id: getOptionFromId(data.tracker_id, profilesLabels, data.tracker?.fio),
      supervisor_id: getOptionFromId(data.supervisor_id, profilesLabels, data.supervisor?.fio),
    }),
    [data, profilesLabels],
  );

  const [peopleData, setPeopleData] = useState<IData>(initialData);

  useEffect(() => {
    setPeopleData(initialData);
  }, [initialData]);

  const onUndo = (field: keyof IData, startData: DropdownSingleType) => {
    setPeopleData(prev => ({
      ...prev,
      [field]: startData,
    }));
  };
  const mutation = UseMutationDraft(pageId);

  const onSave = (field: keyof IData) => {
    const value = peopleData[field];
    mutation.mutate({
      [field]: value?.value || null,
    });
  };

  return (
    <StyledTable>
      <StatusChecker errorsArray={[isErrorProfiles]} loadingsArray={[isLoadingProfiles]}>
        <StyledContentRow $templateColumns="33% 65%">
          <TextHeaderMedium>Инициатор</TextHeaderMedium>
          <StyledFounder $color={Colors.textDark}>
            {data.founder.fio || data.founder.email1}
          </StyledFounder>
        </StyledContentRow>
        <InformationDraftRow
          title={'Куратор'}
          isGray={false}
          isNotEditing={is_project}
          value={peopleData['supervisor_id']?.label}
          onUndo={() => onUndo('supervisor_id', initialData['supervisor_id'])}
          onSave={() => onSave('supervisor_id')}>
          <SingleDropdown
            isFocus
            labels={supervisorsLabels}
            value={peopleData['supervisor_id']}
            handleChange={option => setPeopleData(prev => ({...prev, ['supervisor_id']: option}))}
          />
        </InformationDraftRow>

        {!is_expert && !is_customer && (
          <ContentBoardMenuButton
            title={'Добавить участника'}
            action={() => {
              setIsRegistrationModalShown(true);
            }}
            image={<PlusIcon />}
          />
        )}
        {isRegistrationModalShown && (
          <RegistrationModal onClose={() => setIsRegistrationModalShown(false)} />
        )}
      </StatusChecker>
    </StyledTable>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps)(DraftsParticipantsContent);
