import {instance} from 'api';

import {IApiResponse} from 'types/services';
import {IAcceleratorsItem} from 'types/state/Accelerators';

export const AcceleratorService = {
  async getAll() {
    const response = await instance.get<IApiResponse<IAcceleratorsItem>>(
      '/accelerator/?is_active=true',
    );

    return response.data.results;
  },

  async getById(id: number) {
    const response = await instance.get<IAcceleratorsItem>(`/accelerator/${id}/`);

    return response.data;
  },
};
