import {useEffect, RefObject} from 'react';

const UseClickOutside = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  type?: 'click' | 'mousedown',
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    if (ref.current) document.addEventListener(type || 'mousedown', handleClickOutside);
    else {
      document.removeEventListener(type || 'mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener(type || 'mousedown', handleClickOutside);
    };
  }, [ref, callback, type]);
};

export default UseClickOutside;
