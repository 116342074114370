import styled from 'styled-components';

import React, {PropsWithChildren} from 'react';

export const TableStyled = styled.div<{$isGray?: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 220px - 232px);

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb {
    background-color: red; /* цвет плашки */
    border-radius: 8px; /* закругления плашки */
  }

  @media (max-width: 1265px) {
    height: calc(100vh - 220px - 286px);
  }

  @media (max-width: 1100px) {
    height: calc(100vh - 220px - 340px);
  }
`;

const Table = ({children}: PropsWithChildren) => {
  return <TableStyled>{children}</TableStyled>;
};

export default Table;
