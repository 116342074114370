import styled from 'styled-components';

import React from 'react';

import {MenuButtonProps} from 'types/components';

export const StyledMenuButton = styled.button<{
  $isActive: boolean;
  $isDropdown: boolean;
  $longestLabelWidth?: number;
  $isPaddingSmall?: boolean;
  $isRed?: boolean;
  $isDisabled?: boolean;
}>`
  align-items: center;
  border-radius: 10px;
  cursor: ${({$isDisabled}) => ($isDisabled ? 'default' : 'pointer')};
  display: flex;
  font-size: 16px;
  gap: 16px;
  justify-content: center;
  line-height: 21px;
  padding: ${props => (props.$isPaddingSmall ? '8px' : '10px 13px')};
  text-align: center;
  background-color: ${({theme, $isActive}) => ($isActive ? theme.colors.oceanBlue : 'transparent')};
  border: ${({$isRed}) => `1px solid ${$isRed ? 'red' : '#d7d8dc'}`};
  max-height: 40px;
  color: ${({theme, $isDisabled}) => $isDisabled && theme.colors.green};

  & svg {
    width: 20px;
    height: 20px;
  }

  & svg path {
    fill: ${({theme, $isActive}) => ($isActive ? theme.colors.white : theme.colors.grey)};
  }

  &:hover {
    background: ${({theme, $isActive}) =>
      $isActive ? theme.colors.grey : theme.colors.greyCircuit};
    background: ${({theme, $isDisabled}) =>
      $isDisabled ? 'transparent' : theme.colors.greyCircuit};
  }

  &:active {
    background: ${({$isDisabled}) => (!$isDisabled ? 'var(--blue-4, #003274)' : 'transparent')};
  }

  &:active svg path {
    fill: ${({theme}) => theme.colors.white};
  }

  &:active span {
    color: ${({theme, $isDisabled}) => ($isDisabled ? 'transparent' : theme.colors.white)};
  }
`;

const ContentBoardMenuButton = ({
  title,
  action,
  image,
  isActive = false,
  isDropdown = false,
  longestLabelWidth,
  isPaddingSmall,
  isRed,
  isDisabled,
}: MenuButtonProps) => {
  return (
    <StyledMenuButton
      $isRed={isRed}
      $longestLabelWidth={longestLabelWidth}
      $isDropdown={isDropdown}
      $isActive={isActive}
      $isDisabled={isDisabled}
      onClick={action}
      $isPaddingSmall={isPaddingSmall}>
      {title && <span>{title}</span>}
      {image}
    </StyledMenuButton>
  );
};

export default ContentBoardMenuButton;
